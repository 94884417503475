import React from "react";
import { Image, Box, Flex, Text, Button, Stack } from "@chakra-ui/react";
import LoginPageImg from "./../assets/Group-140.png";
import Image404 from "./../assets/404.png";

const NotFound = () => {
  const token = localStorage.getItem("token");

  const handleToHome = () => {
    window.location.href = "/dashboard";
  };

  const handleToLogin = () => {
    window.location.href = "/";
  };

  return (
    <Box
      h="100vh"
      bgImage={`url(${LoginPageImg})`}
    >
      <Flex
        h="100vh"
        w={"100vw"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        bg="white"
        gap={4}
      >
        <Image src={Image404} h={"40vh"} />
        <Stack textAlign={"center"}>
          <Text color={"blue.700"} fontSize={"75px"} fontWeight={"700"}>
            Oops..
          </Text>
          <Text color={"blue.700"} fontSize={"40px"} fontWeight={"700"}>
            Halaman yang anda cari tidak ditemukan!
          </Text>
          {/* <Text fontSize={"15px"} fontWeight={"400"}>
            
          </Text>
          <Text fontSize={"15px"} fontWeight={"400"}>
            If you need to access this page, Please login or contact your Admin.
          </Text> */}
          <Box>
            {token ? (
              <Button
                bg={"gray.300"}
                boxShadow={"lg"}
                onClick={() => handleToHome()}
              >
                Kembali ke Dashboard
              </Button>
            ) : (
              <Button
                bg={"gray.300"}
                boxShadow={"lg"}
                onClick={() => handleToLogin()}
              >
                Login
              </Button>
            )}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
};

export default NotFound;
