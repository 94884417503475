import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Protected(props) {
    const navigate = useNavigate();
    const { Page, access } = props;

    useEffect(() => {
        const checkAccess = () => {
            let login = localStorage.getItem("login");
            let currentPath = window.location.pathname; // Get the current path
    
            if (!login) {
                localStorage.setItem("loginStatus", "Please login to view this page!");
                navigate("/", { replace: true });
            } else if (currentPath === "/dashboard" && access === undefined) {
                // Set default access for the dashboard if access is undefined
                navigate("/dashboard", { replace: true });
            } else if (currentPath !== "/dashboard" && !access) {
                localStorage.setItem("loginStatus", "You do not have access to this page!");
                navigate("/unauthorized", { replace: true });
            }
        };
    
        checkAccess();
    }, [navigate, access]);
    

    return <Page />;
}
