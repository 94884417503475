import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosSpeedometer, IoIosLogOut } from "react-icons/io";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { MdManageAccounts } from "react-icons/md";
import {
  FaBusinessTime,
  FaUsers,
  FaChalkboardUser,
  FaBriefcase,
  FaGear,
} from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import { IoDocuments } from "react-icons/io5";
import { FaHourglass, FaChartBar } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import {
  List,
  ListItem,
  ListIcon,
  Flex,
  Text,
  Image,
  Icon,
  Box,
  Link,
} from "@chakra-ui/react";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import axios from "axios";
import LogoKT from "../../assets/logoKT - gray.png";
import { GrHostMaintenance } from "react-icons/gr";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const roleId = localStorage.getItem("role_id");
  const accessData = localStorage.getItem("access_permissions")
    ? JSON.parse(localStorage.getItem("access_permissions"))
    : {};

  const location = useLocation();

  const isActiveLink = (href) => {
    return location.pathname === href;
  };

  useEffect(() => {
    const handlePageChange = () => {
      localStorage.removeItem("selectedContentType");
    };

    // Add event listener to remove contentType item from localStorage when clicking a sidebar link
    document.querySelectorAll(".sidebar-list-item a").forEach((link) => {
      link.addEventListener("click", handlePageChange);
    });

    return () => {
      // Remove event listener when component unmounts
      document.querySelectorAll(".sidebar-list-item a").forEach((link) => {
        link.removeEventListener("click", handlePageChange);
      });
    };
  }, []);

  const listItems = [
    {
      href: "/dashboard",
      icon: IoIosSpeedometer,
      text: "Dashboard",
      access: accessData.access_dashboard,
    },
    {
      href: "/pending-task",
      icon: HiOutlineClipboardDocumentList,
      text: "Tugas Saya",
      access: accessData.access_tasks,
    },
    {
      href: "/project",
      icon: FaBriefcase,
      text: "Projek",
      access: accessData.access_project,
    },
    {
      href: "/attendance",
      icon: FaChalkboardUser,
      text: "Kehadiran",
      access: accessData.access_attendance,
    },
    // {
    //   href: "/maintenance",
    //   icon: GrHostMaintenance,
    //   text: "Maintenance",
    //   access: accessData.access_project,
    // },
    // {
    //   href: "/daily-task",
    //   icon: FaTasks,
    //   text: "Tugas Harian",
    //   access: accessData.access_additional_tasks,
    // },
    {
      href: "/task-adhoc",
      icon: FaTasks,
      text: "Tugas Ad hoc",
      access: accessData.access_adhoc_tasks,
    },
    
    {
      href: "/management-sales",
      icon: MdManageAccounts,
      text: "Manajemen Sales",
      access: accessData.access_sales_management,
    },
    {
      href: "/reimbursement",
      icon: FaMoneyBillWave,
      text: "Klaim",
      access: true,
    },
    {
      href: "/document-archive",
      icon: IoDocuments,
      text: "Arsip Dokumen",
      access: accessData.access_update_document_archive,
    },
    {
      href: "/manageUser",
      icon: FaUsers,
      text: "Kelola User",
      access: accessData.access_manage_user,
    },
    {
      href: "/log-history",
      icon: FaBusinessTime,
      text: "Riwayat Tugas",
      access: true,
    },
    {
      href: "/performance",
      icon: FaChartBar,
      text: "Performa Karyawan",
      access: accessData.access_performance_karyawan,
    },
    {
      href: "/scheduler",
      icon: MdOutlineDateRange,
      text: "Scheduler",
      access: accessData.access_project_report,
    },
    {
      href: "/setting",
      icon: FaGear,
      text: "Pengaturan",
      access: accessData.access_settings,
    },
  ];

  return (
    <aside
      id="sidebar"
      className={`${
        openSidebarToggle ? "sidebar-responsive" : ""
      } h-full flex flex-col`}
    >
      <Box display={{ base: "block"}} className="sidebar-title">
        <Icon
          display={{ base: "block", lg: "none" }}
          as={BsFillArrowLeftSquareFill}
          onClick={OpenSidebar}
          boxSize={8}
        />
      </Box>
      <List spacing={3} mx="2">
        {listItems.map(
          (item, index) =>
            item.access && (
              <ListItem
                key={index}
                p="7px"
                justifyItems="center"
                m="0 2 2 2"
                borderRadius="full"
                className={`sidebar-list-item ${
                  isActiveLink(item.href) ? "active" : ""
                }`}
              >
                <a
                  className="flex items-center text-md text-white"
                  href={item.href}
                >
                  <ListIcon as={item.icon} color="white" fontSize="sm" />
                  <Text fontSize="sm">{item.text}</Text>
                </a>
              </ListItem>
            )
        )}
      </List>
      <Box
        mt="auto"
        p={3}
        display={"flex"}
        flexDirection={"row"}
        gap={"center"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Text fontSize={"10px"} fontWeight={"400"} color={"white"}>
          Powered By
        </Text>
        <Link href="https://kejartugas.com">
          <Image src={LogoKT} h={{ base: "3vh", md: "6vh" }} alt="Logo" />
        </Link>
      </Box>
    </aside>
  );
}

export default Sidebar;
