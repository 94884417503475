import React from "react";
import { Image, Box, Flex, Text, Button, Stack } from "@chakra-ui/react";
import LogoKTSymbol from "./../../assets/logoKT - Symbol.png";
import Image403 from "./../../assets/403.png";
import LoginPageImg from "./../../assets/Group-140.png";

const Unauthorized = () => {
  const token = localStorage.getItem("token");

  const handleToHome = () => {
    window.location.href = "/dashboard";
  };

  const handleToLogin = () => {
    window.location.href = "/";
  };

  return (
    <Box
      h="100vh"
      bgImage={`url(${LoginPageImg})`}
    >
      <Flex
        h="100vh"
        w={"100vw"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        bg="white"
        gap={4}
      >
        <Image src={Image403} h={"40vh"} />
        <Stack textAlign={"center"}>
          <Text color={"blue.700"} fontSize={"75px"} fontWeight={"700"}>
            Oops..
          </Text>
          <Text color={"blue.700"} fontSize={"40px"} fontWeight={"700"}>
            Anda tidak diizinkan mengakses halaman ini
          </Text>
          <Text fontSize={"15px"} fontWeight={"400"}>
            Halaman yang ingin anda akses memiliki batasan akses.
          </Text>
          <Text fontSize={"15px"} fontWeight={"400"}>
            Jika anda ingin mengakses halaman ini, silahkan menghubungi admin.
          </Text>
          <Box>
            {token ? (
              <Button
                bg={"gray.300"}
                boxShadow={"lg"}
                onClick={() => handleToHome()}
              >
                Kembali ke Dashboard
              </Button>
            ) : (
              <Button
                bg={"gray.300"}
                boxShadow={"lg"}
                onClick={() => handleToLogin()}
              >
                Login
              </Button>
            )}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Unauthorized;
