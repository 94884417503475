import React from 'react';

const Footer = () => {
    return (
        <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            <p className="text-black p-2 text-sm justify-items-center">
              &copy; {new Date().getFullYear()} KejarTugas.com by <a href='https://innovation.co.id/'>PT Global Innovation Technology</a>. All rights
              reserved.
            </p>
          </div>
    );
};

export default Footer;